import React from "react";
import PropTypes from "prop-types";

import ContentLayout from "Components/ContentLayout";
import ProjectAccessList from "Containers/ProjectAccessList";

class ProjectAccess extends React.Component {
  render() {
    return (
      <ContentLayout className="settings">
        <ProjectAccessList
          organizationId={this.props.params.organizationId}
          projectId={this.props.params.projectId}
        />
      </ContentLayout>
    );
  }
}

ProjectAccess.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired,
    environmentId: PropTypes.string
  })
};

export default ProjectAccess;
