import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";

import AccessForm from "Components/AccessForm";

class EnvironmentAccessListFields extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.save = this.save.bind(this);
    this.onAccessesLoaded = this.onAccessesLoaded.bind(this);
    this.state = {
      accesses: [],
      accessesAreLoading: true
    };
  }

  componentDidMount() {
    this.props.environments.forEach(environment =>
      this.props.loadAccesses(environment)
    );
    this.setState(() => ({
      accesses: [...this.props.accesses.valueSeq().toJS()]
    }));

    if (this.props.access["id"]) {
      this.setState({ currentAccessUser: this.props.access.getUser() });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.accesses !== this.props.accesses) {
      this.setState(() => ({
        accesses: [...nextProps.accesses.valueSeq().toJS()]
      }));
    }
  }

  componentDidUpdate() {
    // Accesses are loaded asynchronously so this is the most straightforward way
    // to check if the accesses for all the available environments are loaded.
    // @todo: Look into creating a que of some sort for this that can set a flag for completion.
    if (
      this.props.environments.size === this.props.accesses.size &&
      this.state.accessesAreLoading !== false
    ) {
      this.onAccessesLoaded();
    }
  }

  onAccessesLoaded() {
    this.setState({ accessesAreLoading: false });
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;
      nextState.accesses[index] = {
        ...(this.state.accesses[index] || this.props.accesses[index]),
        [field]: value
      };
      return nextState;
    });
  }

  save(values) {
    let email = this.props.userId
      ? this.state.currentAccessUser["email"]
      : values.email;
    let access = {
      role: values.superUser ? "admin" : "viewer",
      email: email,
      id: values.userId
    };

    this.props.updateProjectAccesses(access);
    if (access.role !== "admin") {
      Object.keys(values.accesses).forEach(environmentId => {
        if (!values.accesses[environmentId].role) {
          return;
        }

        // Update env access...
        this.props.updateAccess(
          this.props.environments.get(environmentId),
          email,
          values.accesses[environmentId],
          this.props.editedLine
        );
      });
    }
  }

  render() {
    const environments = this.props.environments.valueSeq();
    const accesses = environments.reduce((accesses, environment) => {
      const access = this.props.accesses.getIn(
        [environment.id, this.props.userId],
        {}
      );

      accesses[environment.id] = access;
      return accesses;
    }, {});

    const { environmentForm } = this.props;

    return (
      <div>
        <AccessForm
          accesses={accesses}
          userId={this.props.userId}
          environments={environments}
          key={`${this.props.userId}-access-edit`}
          onSave={this.save}
          onCancel={this.props.onCancel}
          onDelete={this.props.onDelete}
          errors={this.props.errors}
          isNew={this.props.isNew}
          enabled={this.props.enabled}
          isLoading={this.props.isLoading}
          access={this.access}
          deletePermission={this.props.deletePermission}
          superUser={this.props.access["role"] === "admin"}
          accessesAreLoading={this.state.accessesAreLoading}
          environmentForm={environmentForm ? environmentForm : false}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadAccesses: environment =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(
        reducer.loadAccesses(
          props.organizationId,
          props.projectId,
          environment.id,
          environment
        )
      )
    ),
  updateAccess: (environment, accessId, access, index) =>
    import("Reducers/environment/access").then(reducer =>
      dispatch(
        reducer.updateAccess(
          props.organizationId,
          props.projectId,
          environment,
          accessId,
          access,
          index
        )
      )
    )
});

const mapStateToProps = (state, props) => {
  const access = state.environmentAccess || new Map();

  return {
    accesses: access.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    errors: access.get("errors", {}),
    isLoading: access.get("loading"),
    // This might be it.
    editedLine: state.projectAccess.get("editedLine")
  };
};

EnvironmentAccessListFields.propTypes = {
  loadAccesses: PropTypes.func,
  onChange: PropTypes.func,
  updateAccess: PropTypes.func,
  onCancel: PropTypes.func,
  environment: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  accesses: PropTypes.object,
  access: PropTypes.object,
  userId: PropTypes.string,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  enabled: PropTypes.bool,
  environments: PropTypes.object,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  deletePermission: PropTypes.bool,
  onDelete: PropTypes.func,
  environmentForm: PropTypes.bool,
  editLine: PropTypes.func,
  updateProjectAccesses: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnvironmentAccessListFields);
