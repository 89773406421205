import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import ReactTooltip from "react-tooltip";

import Error from "Components/Error";
import EnvironmentAccessListFields from "Containers/EnvironmentAccessListFields";
import User from "./User";
import UserIcon from "Components/icons/UserIcon";
import SettingLine from "Components/SettingLine";
import Heading2 from "Components/styleguide/Heading2";
import AddLink from "Components/AddLink";
import LockIcon from "Icons/LockIcon";
import Loading from "Components/Loading";
import ModalConfirmDelete from "Components/ModalConfirmDelete";
import PageDescription from "Components/PageDescription";

const InfoLayout = styled.div`
  display: flex;
  align-items: center;
  .user {
    width: 75%;
    display: flex;
    flex-align: center;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .display-name {
      font-weight: bold;
      font-size: 16px;
      width: 50%;
    }
    .email {
      font-weight: normal;
      font-size: 15px;
    }
  }
  .role {
    display: inline-block;
    align-items: center;
    font-size: 13px;
    color: #4a495e;
    font-weight: 600;
    &:first-letter {
      text-transform: uppercase;
    }
    &.account-owner {
      display: inline-flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .lock-icon {
    height: 24px;
    width: 24px;
    display: inline-flex;
    align-items: stretch;
    margin-left: 16px;
    svg {
      width: 100%;
    }
  }
`;

class ProjectAccessList extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewAccess = this.addNewAccess.bind(this);
    this.cancel = this.cancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.editLine = this.editLine.bind(this);
    this.updateProjectAccesses = this.updateProjectAccesses.bind(this);
    this.state = {
      accesses: [],
      isModalOpen: false
    };
  }

  componentDidMount() {
    this.props.loadAccesses(this.props.project);
    this.setState(() => ({
      accesses: [...this.props.accesses.valueSeq().toJS()]
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.project !== this.props.project) {
      this.props.loadAccesses(nextProps.project);
    }
    if (nextProps.accesses !== this.props.accesses) {
      this.setState(() => ({
        accesses: [...nextProps.accesses.valueSeq().toJS()]
      }));
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;
      nextState.accesses[index] = {
        ...(this.state.accesses[index] || this.props.accesses[index]),
        [field]: value
      };

      return nextState;
    });
  }

  updateProjectAccesses(access) {
    this.props.updateAccess(this.props.project, access, access.id);
  }

  addNewAccess() {
    this.setState(prevState => {
      const nextState = prevState;
      nextState.isNew = true;
      nextState.accesses.unshift({});
      this.props.editLine(0, true);
      return nextState;
    });
  }

  editLine(index, access) {
    this.props.editedLine === index || this.props.isNew
      ? this.cancel()
      : this.props.editLine(index);
    if (this.props.editedLine !== index && access) {
      this.setState({
        accessBeingEdited: {
          user: access.getUser(),
          access
        }
      });
    }
  }

  cancel() {
    if (this.props.isNew) {
      this.setState(prevState => {
        const nextState = prevState;
        nextState.isNew = false;
        nextState.accesses.splice(0, 1);
        nextState.accessBeingEdited = null;
        return nextState;
      });
    }
    this.props.cancelUpdateAccess();
  }

  openModal(access) {
    this.setState({
      isModalOpen: true,
      access
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false
    });
  }

  render() {
    const { errors } = this.props;
    if (!this.props.project) {
      return false;
    }

    return (
      <div>
        <LiveMessage
          message={`${this.props.project &&
            this.props.project.title} project-level access settings`}
          aria-live="polite"
        />
        {this.props.project.hasPermission &&
          this.props.project.hasPermission("#manage-access") && (
            <AddLink onClick={this.addNewAccess} />
          )}
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          Access
        </Heading2>
        <PageDescription>
          Configure your users access to all environments or to select
          environments of this project.<br />Please note that you will need to
          redeploy an environment for changes to take effect on it.
        </PageDescription>
        <section aria-labelledby="settings-heading">
          {errors &&
            errors.code && (
              <Error>
                {this.props.errors.code === 409 && (
                  <React.Fragment>
                    This user has already been added to this project. You can
                    edit this user's access below.
                  </React.Fragment>
                )}
              </Error>
            )}
          {(this.state.accesses || this.props.accesses.valueSeq()).map(
            (access, index) => {
              return (
                <div key={`${access.id}-${index}-read`}>
                  <SettingLine
                    id={`${access.id}-${index}-read`}
                    icon={
                      <UserIcon
                        size={30}
                        backgroundColor="black"
                        color="white"
                        padding={10}
                      />
                    }
                    info={
                      <InfoLayout>
                        <User userId={access.user} />
                        {access.hasPermission &&
                        access.hasPermission("#edit") ? (
                          <span className="role">
                            {access.role === "admin"
                              ? "Project admin"
                              : access.role}
                          </span>
                        ) : (
                          <span className="role account-owner">
                            Account owner{" "}
                            <span
                              className="lock-icon"
                              data-tip
                              data-for={`${access.id}-${index}-tooltip`}
                            >
                              <LockIcon />
                              <ReactTooltip
                                id={`${access.id}-${index}-tooltip`}
                                place="top"
                                type="dark"
                                effect="solid"
                              >
                                You do not have permission to edit this user
                              </ReactTooltip>
                            </span>
                          </span>
                        )}
                      </InfoLayout>
                    }
                    isNew={!access.id}
                    addNewTitle="Add user"
                    isOpen={this.props.editedLine === index}
                    openText={
                      access &&
                      access.hasPermission &&
                      access.hasPermission("#edit")
                        ? this.props.intl.formatMessage({ id: "edit" })
                        : this.props.intl.formatMessage({ id: "view" })
                    }
                    onClick={() => this.editLine(index, access)}
                  >
                    {this.props.editedLine === index && (
                      <EnvironmentAccessListFields
                        key={`${access.id}-${index}-edit`}
                        projectId={this.props.projectId}
                        environmentId={this.props.environmentId}
                        organizationId={this.props.organizationId}
                        userId={access.user}
                        access={access}
                        editLine={this.props.editLine}
                        isNew={this.props.isNew}
                        environments={this.props.environments}
                        updateProjectAccesses={this.updateProjectAccesses}
                        onCancel={this.cancel}
                        isLoading={this.props.isLoading}
                        isUpdateLoading={this.props.isUpdateLoading}
                        enabled={
                          !access.id ||
                          (access &&
                            access.hasPermission &&
                            access.hasPermission("#edit"))
                        }
                        deletePermission={
                          access.hasPermission &&
                          access.hasPermission("#delete")
                        }
                        environmentForm={false}
                        onDelete={this.openModal}
                        accessBeingEdited={this.state.accessBeingEdited}
                      />
                    )}
                  </SettingLine>
                </div>
              );
            }
          )}
          <ModalConfirmDelete
            isOpen={this.state.isModalOpen || false}
            closeModal={this.closeModal}
            deleteFunction={() =>
              this.props.delete(this.state.accessBeingEdited.access)
            }
            itemType="user"
            itemName={
              this.state.access &&
              this.state.accessBeingEdited.user["display_name"]
            }
            itemId={this.state.access && this.state.accessBeingEdited.access.id}
          />
          {this.props.isLoading && <Loading />}
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadAccesses: project =>
    import("Reducers/project/access").then(reducer =>
      dispatch(
        reducer.loadAccesses(props.organizationId, props.projectId, project)
      )
    ),

  updateAccess: (project, access, accessId = false, index = false) =>
    import("Reducers/project/access").then(reducer =>
      dispatch(
        reducer.updateAccess(
          props.organizationId,
          props.projectId,
          project,
          accessId,
          access,
          index
        )
      )
    ),
  editLine: (index, isNew) =>
    import("Reducers/project/access").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  delete: access =>
    import("Reducers/project/access").then(reducer =>
      dispatch(
        reducer.deleteAccess(props.organizationId, props.projectId, access)
      )
    ),
  cancelUpdateAccess: () =>
    import("Reducers/project/access").then(reducer =>
      dispatch(reducer.cancelUpdateAccess())
    )
});

const mapStateToProps = (state, props) => {
  const access = state.projectAccess || new Map();
  const environment = state.environment || new Map();
  const project = state.project || new Map();

  return {
    accesses: access.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    editedLine: access.get("editedLine"),
    project: project.getIn(["data", props.organizationId, props.projectId]),
    isNew: access.get("isNew"),
    errors: access.get("errors", {}),
    environments: environment.getIn(
      ["data", props.organizationId, props.projectId],
      new Map()
    ),
    newEnvironmentAccess: state.environmentAccess.get("newAccess"),
    isLoading: access.get("loading"),
    isUpdateLoading: access.get("updateLoading")
  };
};

ProjectAccessList.propTypes = {
  loadAccesses: PropTypes.func,
  onChange: PropTypes.func,
  updateAccess: PropTypes.func,
  editLine: PropTypes.func,
  delete: PropTypes.func,
  cancelUpdateAccess: PropTypes.func,
  environment: PropTypes.object,
  project: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  accesses: PropTypes.object,
  environments: PropTypes.object,
  environmentId: PropTypes.string,
  projectId: PropTypes.string,
  isNew: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  isLoading: PropTypes.bool,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  intl: PropTypes.object,
  organizationId: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProjectAccessList));
